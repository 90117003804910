

  

  
.container {
    width: 100%;
    min-height: 74vh;
    padding: 5%;
    /* background-color: #3A3A3A80; */
    background-image: url('../../assets/images/parcour.png');
    
    background-position: center;
    background-size: 100vw 100vh;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
    align-items: center;
}

.box{
    background-color: white;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 4rem;
    border-radius: 0.3rem;
    text-align: center;
    margin-top: 5rem;
}
.felicitation{
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}
.icon{
   /* padding-left: 7.5rem; */
}
.souscriptionTerminée{
    font-size: 1.2rem; 
    font-weight: 400;
    margin-block: 0.5rem;
}
.btn{
    background-color: #254484;
    border-radius: 0.9rem;
    /* margin-left: 1.8rem; */
    margin-top:1rem
}