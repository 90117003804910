body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    background-color: #f7f7f7;
    box-sizing: border-box;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}