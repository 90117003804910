.container {
    width: 100%;
    min-height: 74vh;
    padding: 5%;
    background-image: url('../../assets/images/parcour.png');
    background-position: center;
    background-size: 100vw 100vh;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.titleRow {
    margin-block: 2rem;
}

.titleCol {
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    color: #2d2d2d;
    font-family: 'Montserrat bold', sans-serif;
    font-size: 2rem;

   
}

.stepsBtnContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.stageContainer {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
}

.confirm{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.RetourCol{
    display: flex;
    justify-content: flex-end;
}

.retourButton{
    background-color: #c9c9c9;
    color:white;
    width: 10rem;
    border: none;
    margin-bottom: 3rem;
    border-radius: 1rem;

 
}
.confirmButton{
    background-color: #254484;
    color:white;
    width: 10rem;
    border: none;
    margin-bottom: 3rem;
    border-radius: 1rem;
}