

  

  
.container {
    width: 100%;
    min-height: 74vh;
    padding: 5%;
    background-image: url('../../assets/images/parcour.png');
    
    background-position: center;
    background-size: 100vw 100vh;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.titleRow {
    margin-block: 2rem;
}

.titleCol {
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    color: #E00000;
}

.stepsBtnContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.stageContainer {
    /* height: 1000px; */
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
}

.confirm{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.form {
    background-color: white;
    padding: 2rem;
    border-radius: 20px;
}

.titre {
    color: gray;
}

.souscripteurTitle {
    color: #1a5c9e;
}
.retourButton{
    background-color:rgba(147, 5, 5, 1) !important;
    color:white !important;
    width: 10rem !important;
    border: none !important;
    margin-bottom: 3rem !important;
    border-radius: 1rem !important;
    margin-top:5rem !important
    
  
  
  }
  .confirmButton{
    background-color: #254484 !important;
    color:white !important;
    width: 10rem !important;
    border: none !important;
    margin-bottom: 3rem !important;
    border-radius: 1rem !important;
    margin-top:5rem !important
  }
  .RetourCol{
    display: flex;
    justify-content: flex-end;
  }
  .inputTitle{
    color: #2d2d2d;
    font-family: 'Montserrat medium' sans-serif;
    font-size: 1.1rem;
    margin-top:1rem;
    display: flex;
}
.inputTitle p {
    color:red;
}     


.loaderContainer{
    display: flex;
    flex-direction: column;
    
    text-align: center;
  }